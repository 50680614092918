const appRoutesKeys = {
  home: 'home',
  login: 'login',
  error: 'error',
  forbiddenPage: 'forbiddenPage',
  profile: 'profile',
  editContactInfo: 'editContactInfo',
  calender: 'calender',
  routes: 'routes',
  payments: 'payments',
  feedback: 'feedback',
  carWheelSet: 'carWheelSet',
  todaysAppointments: 'todaysAppointments',
  routeItemOnRoute: 'routeItemOnRoute'
} as const;

type BaseRouteKeys = keyof typeof appRoutesKeys;

type StringOrNumber = string | number;

interface PathConfig {
  todaysAppointments: { routeId: StringOrNumber };
  carWheelSet: { carWheelSetId: StringOrNumber };
  routeItemOnRoute: { routeId: StringOrNumber; routeItemId: StringOrNumber };
}

type InputData<Type extends BaseRouteKeys> = Type extends keyof PathConfig ? PathConfig[Type] : void;

type InputDataOrVoid<Type extends BaseRouteKeys> = InputData<Type> extends void ? void : InputData<Type>;

type RouteDefinition<key extends BaseRouteKeys> = {
  getPath(data: InputDataOrVoid<key>): string;
  getBasePath(): string;
  isBaseRouterPath?: boolean;
};

export const workRoutes: {
  [key in BaseRouteKeys]: RouteDefinition<key>;
} = {
  home: {
    getPath: () => '/',
    getBasePath: () => '/'
  },
  login: {
    getPath: () => '/login',
    getBasePath: () => '/login'
  },
  error: {
    getPath: () => '/error',
    getBasePath: () => '/error'
  },
  forbiddenPage: {
    getPath: () => '/forbidden',
    getBasePath: () => '/forbidden'
  },
  profile: {
    getPath: () => '/profile',
    getBasePath: () => '/profile',
    isBaseRouterPath: true
  },
  editContactInfo: {
    getPath: () => '/editContactInfo',
    getBasePath: () => '/editContactInfo'
  },
  calender: {
    getPath: () => '/calender',
    getBasePath: () => '/calender',
    isBaseRouterPath: true
  },
  routes: {
    getPath: () => '/routes',
    getBasePath: () => '/routes',
    isBaseRouterPath: true
  },
  routeItemOnRoute: {
    getPath: ({ routeId, routeItemId }) => `/appointments/not-next-on-route/${routeId}?routeItemId=${routeItemId}`,
    getBasePath: () => '/appointments/not-next-on-route/:routeId'
  },
  payments: {
    getPath: () => '/payments',
    getBasePath: () => '/payments'
  },
  feedback: {
    getPath: () => '/feedback',
    getBasePath: () => '/feedback'
  },
  carWheelSet: {
    getPath: ({ carWheelSetId }) => `/storage/car-wheel-sets/${carWheelSetId}`,
    getBasePath: () => '/storage/car-wheel-sets/:carWheelSetId'
  },
  todaysAppointments: {
    getPath: ({ routeId }) => `/appointments/${routeId}`,
    getBasePath: () => '/appointments/:routeId'
  }
};
