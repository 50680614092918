import { MenuItem, Select, Typography } from '@mui/material';
import { noddiAsync, URLKeys } from 'noddi-async';
import { AvailableSalesItemForNewCarInBooking, SalesCar } from 'noddi-async/src/types';
import { NoddiChip, NoddiLinearProgressLoader } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { formatCurrencyAmount } from 'noddi-util';
import { Dispatch, SetStateAction } from 'react';

type Props = {
  carSearchResult: SalesCar;
  onAddService: ({ salesItemIds }: { salesItemIds: number[] }) => void;
  setSelectedCar?: Dispatch<SetStateAction<SalesCar | null>>;
  selectedSalesItems: AvailableSalesItemForNewCarInBooking[];
  setSelectedSalesItems: Dispatch<SetStateAction<AvailableSalesItemForNewCarInBooking[]>>;
  excludeSalesItemIds?: number[];
  bookingId: number;
};

const SelectSalesItem = ({
  carSearchResult,
  onAddService,
  setSelectedSalesItems,
  setSelectedCar,
  selectedSalesItems,
  excludeSalesItemIds,
  bookingId
}: Props) => {
  const { data: availableSalesItems, isPending: isAvailableSalesItemsPending } = noddiAsync.useGet({
    type: URLKeys.getSalesItemForNewCarInBooking,
    input: { bookingId, carLicensePlateNumber: carSearchResult.licensePlateNumber!, carCountryCode: 'NO' }
  });

  if (isAvailableSalesItemsPending) {
    return <NoddiLinearProgressLoader />;
  }

  const salesItemOptions = availableSalesItems?.map((salesItem) => {
    return {
      value: salesItem.id,
      label: `${salesItem.name} (${formatCurrencyAmount(salesItem.price.amount, 0, salesItem.price.currency)})`
    };
  });

  const options = salesItemOptions?.filter((item) => !excludeSalesItemIds?.includes(item.value));
  const selectedValues = selectedSalesItems.map((item) => item.id);

  return options ? (
    <>
      <Typography marginTop={2} variant='subtitle1'>
        Select services
      </Typography>
      <Select
        fullWidth
        multiple
        value={selectedValues}
        name='salesItems'
        label='Select sales items'
        onChange={(e) => {
          const selectedValues = e.target.value as number[];
          const salesItems = availableSalesItems?.filter((item) => selectedValues.includes(item.id)) ?? [];
          setSelectedSalesItems(salesItems);
        }}
        renderValue={(selected) => {
          return selected.map((value) => (
            <div key={value} className='m-3'>
              <NoddiChip label={options?.find((option) => option.value === value)?.label} />
            </div>
          ));
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>

      <NoddiButton
        className='mt-6 w-full'
        disabled={selectedValues.length === 0}
        onPress={() => {
          if (setSelectedCar) {
            setSelectedCar(carSearchResult);
          }
          onAddService({ salesItemIds: selectedValues });
        }}
      >
        <Typography variant='body1'>Confirm</Typography>
      </NoddiButton>
    </>
  ) : (
    <Typography marginTop={2} variant='subtitle1'>
      No sales items found
    </Typography>
  );
};

export default SelectSalesItem;
