import { CustomerCarGeneration } from 'noddi-async/src/types';
import { NoddiDialog, ShowMoreDescription } from 'noddi-ui';
import { NoddiButton, noddiColors, NoddiIcon } from 'noddi-ui-common';
import { useState } from 'react';
import { getUniquePsiValues } from '../../utils/car';

const InfoRow = ({ label, value }: { label: string; value: string }) => {
  return (
    <div className='flex items-center'>
      <p>{label}:</p>
      <p className='ml-2 font-bold'>{value}</p>
    </div>
  );
};

const PsiPressureInfo = ({
  tirePressures
}: {
  tirePressures: CustomerCarGeneration['dimensions']['frontTirePsiPressures'];
}) => {
  return (
    <div>
      {tirePressures.map(({ psi, dimensions }, index) => (
        <div key={index} className='border-b py-4 last:border-0'>
          <div className='mb-2 flex items-center justify-between'>
            <div className='flex items-center'>
              <NoddiIcon name='WheelAngle' />
              <p>Wheel dimensions</p>
            </div>
            <p className='font-bold text-4'>PSI</p>
          </div>

          <ul className='ml-6'>
            {dimensions.map((value) => (
              <li key={value}>
                <div className='flex justify-between'>
                  <p>{value}</p>
                  <p className='ml-1 font-bold text-4'>{psi}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

const PsiRow = ({
  tireInfo,
  onClick,
  text
}: {
  tireInfo: { psiValues: string; hasMultipleValues: boolean };
  onClick: () => void;
  text: string;
}) => {
  return (
    <div className='flex items-center justify-between'>
      <InfoRow label={text} value={`${tireInfo.psiValues} PSI`} />
      {tireInfo.hasMultipleValues && (
        <NoddiButton startIcon='ArrowSquareRightCorner' className='py-0' variant='link' onPress={onClick}>
          Details
        </NoddiButton>
      )}
    </div>
  );
};

interface CarSpecificationsProps {
  carGeneration: CustomerCarGeneration;
}

const CarSpecifications = ({ carGeneration }: CarSpecificationsProps) => {
  const { frontTirePsiPressures, rearTirePsiPressures } = carGeneration.dimensions;
  const uniqueFrontTireValues = getUniquePsiValues(frontTirePsiPressures);
  const uniqueRearTireValues = getUniquePsiValues(rearTirePsiPressures);

  const [moreInfoFrontTires, setMoreInfoFrontTires] = useState(false);
  const [moreInfoRearTires, setMoreInfoRearTires] = useState(false);

  const hasTorqueValues = carGeneration.wheelLugNutTorqueAluminiumNm && carGeneration.wheelLugNutTorqueSteelNm;

  const isEqualTorqueValue = carGeneration.wheelLugNutTorqueAluminiumNm === carGeneration.wheelLugNutTorqueSteelNm;

  return (
    <div>
      {carGeneration.makeInformation && (
        <div>
          <p>{carGeneration.makeInformation}</p>
        </div>
      )}
      {uniqueFrontTireValues.psiValues && (
        <PsiRow
          text='Front tire pressure'
          tireInfo={uniqueFrontTireValues}
          onClick={() => setMoreInfoFrontTires(true)}
        />
      )}
      {uniqueRearTireValues.psiValues && (
        <PsiRow text='Rear tire pressure' tireInfo={uniqueRearTireValues} onClick={() => setMoreInfoRearTires(true)} />
      )}

      {isEqualTorqueValue ? (
        <InfoRow
          label='Torque'
          value={hasTorqueValues ? `${carGeneration.wheelLugNutTorqueAluminiumNm} Nm` : 'Not specified'}
        />
      ) : (
        <div>
          <InfoRow label='Torque aluminium' value={`${carGeneration.wheelLugNutTorqueAluminiumNm} Nm`} />
          <InfoRow label='Torque steel' value={`${carGeneration.wheelLugNutTorqueSteelNm} Nm`} />
        </div>
      )}
      {carGeneration.information && (
        <div className='mt-1 flex gap-2'>
          <div>
            <NoddiIcon name='Info' color={noddiColors.signal.warning} />
          </div>
          <ShowMoreDescription description={carGeneration.information} />
        </div>
      )}
      {carGeneration.makeInformation && (
        <div className='flex items-center'>
          <NoddiIcon name='Info' color={noddiColors.signal.warning} />
          <ShowMoreDescription description={carGeneration.makeInformation} />
        </div>
      )}
      {moreInfoFrontTires && (
        <NoddiDialog title='Front tire pressure' open={moreInfoFrontTires} onClose={() => setMoreInfoFrontTires(false)}>
          <PsiPressureInfo tirePressures={frontTirePsiPressures} />
        </NoddiDialog>
      )}

      {moreInfoRearTires && (
        <NoddiDialog title='Rear tire pressure' open={moreInfoRearTires} onClose={() => setMoreInfoRearTires(false)}>
          <PsiPressureInfo tirePressures={rearTirePsiPressures} />
        </NoddiDialog>
      )}
    </div>
  );
};
export default CarSpecifications;
