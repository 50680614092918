import { noddiAsync, URLKeys } from 'noddi-async';
import { BookingItemOnRoute as BookingItemOnRouteType } from 'noddi-async/src/types';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { getCarDisplayName, IconBasedOnSalesItem, NoddiChip, NoddiDialog, useNoddiToast } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { differenceBetweenDates } from 'noddi-util';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { UnableToCompleteCarModal } from '../../components/ui/BookingServiceCard/UnableToCompleteCar';
import AddExtraServiceToBookingItem from './AddExtraServiceToBookingItem';
import CarSpecifications from './CarSpecifications';
import MeasureTiresAndCompleteBookingItem from './CompleteBookingItem';
import MeasureTires from './CompleteBookingItem/MeasureTires';

interface BookingItemOnRouteProps {
  bookingItem: BookingItemOnRouteType;
  routeItemId: number;
  bookingId: number;
}

const BookingItemOnRoute = ({ bookingItem, routeItemId, bookingId }: BookingItemOnRouteProps) => {
  const { routeId } = useParams();
  const { noddiToast } = useNoddiToast();

  const carName = getCarDisplayName(bookingItem.car);
  const [isCarSetAsFinished, setIsCarSetAsFinished] = useState(false);
  const [editCarAfterFinished, setEditCarAfterFinished] = useState(false);
  const [unableToCompleteModal, setUnableToCompleteModal] = useState(false);
  const [addExtraSalesItemsModalOpen, setAddExtraSalesItemsModalOpen] = useState(false);
  const [measureTiresModalOpen, setMeasureTiresModalOpen] = useState(false);
  const { orderLines } = bookingItem;

  const bookingItemIsFinished = bookingItem.completedAt || bookingItem.isAllOrderLinesUnableToComplete;
  const showEditCarAfterFinished = !editCarAfterFinished && bookingItemIsFinished;

  const salesItemsOnBookingItem = bookingItem.orderLines.map((item) => ({
    id: item.salesItem.id,
    name: item.salesItem.workerName
  }));

  const { mutateAsync: markBookingItemAsComplete, isPending: isCompleteBookingItemLoading } = noddiAsync.usePost({
    type: URLKeys.postMarkBookingItemsAsComplete,
    queryConfig: {
      onSuccess: async () => {
        await invalidateQueryExactMatch({
          urlKey: URLKeys.getBookingItemsForRoute,
          input: {
            routeItemId
          }
        });
        noddiToast.success('Car finished');
      },
      onError: async (error) => {
        noddiToast.asyncError(error);
      }
    }
  });

  const hasMeasuredThisWeek = bookingItem.car?.latestMeasuredAt
    ? Math.abs(differenceBetweenDates(bookingItem.car.latestMeasuredAt, new Date(), 'days')) < 7
    : false;

  return (
    <div className='py-6'>
      <p className='text-5 font-semibold sm:mb-5'>{carName}</p>
      {showEditCarAfterFinished ? (
        <div className='mt-4 flex justify-between'>
          <NoddiChip
            variant={bookingItem.isAllOrderLinesUnableToComplete ? 'destructive' : 'success'}
            label={bookingItem.isAllOrderLinesUnableToComplete ? 'Unable to complete' : 'Finished'}
            icon={bookingItem.isAllOrderLinesUnableToComplete ? 'Cross' : 'Check'}
          />
          <NoddiButton variant='ghost' startIcon='Edit' className='ml-4' onPress={() => setEditCarAfterFinished(true)}>
            Edit
          </NoddiButton>
        </div>
      ) : (
        <>
          {bookingItem.car?.carGeneration && <CarSpecifications carGeneration={bookingItem.car.carGeneration} />}
          <NoddiButton onPress={() => setMeasureTiresModalOpen(true)} variant='link' startIcon='Plus' className='pl-0'>
            Add wheel measurements
          </NoddiButton>

          <div className='flex items-center justify-between'>
            <p className='mb-2 mt-4 font-bold'>My tasks</p>
            <NoddiButton
              variant='link'
              startIcon='Plus'
              className='mt-2'
              onPress={() => setAddExtraSalesItemsModalOpen(true)}
            >
              Add task
            </NoddiButton>
          </div>
          {orderLines.map(({ id, salesItem: { bookingCategorySlug, workerName } }) => (
            <div key={id} className='mt-2 flex items-center gap-2 rounded-lg border bg-systemColors-purpleBg p-4'>
              <IconBasedOnSalesItem categorySlug={bookingCategorySlug} />
              <p>{workerName}</p>
            </div>
          ))}

          <div className='mt-4 flex flex-col items-center gap-4'>
            <NoddiButton className='w-full' variant='secondary' onPress={() => setUnableToCompleteModal(true)}>
              Unable to complete
            </NoddiButton>
            <NoddiButton
              className='w-full'
              loading={isCompleteBookingItemLoading}
              onPress={async () => {
                // if the car wheels has been measured, mark it as complete
                if (hasMeasuredThisWeek) {
                  await markBookingItemAsComplete({ bookingItemId: bookingItem.id });
                } else {
                  setIsCarSetAsFinished(true);
                }
              }}
            >
              Finish car
            </NoddiButton>
          </div>
        </>
      )}
      {isCarSetAsFinished && (
        <NoddiDialog title='Almost there' onClose={() => setIsCarSetAsFinished(false)} open={isCarSetAsFinished}>
          <MeasureTiresAndCompleteBookingItem
            carId={bookingItem.car.id}
            routeItemId={routeItemId}
            bookingItemId={bookingItem.id}
          />
        </NoddiDialog>
      )}
      {measureTiresModalOpen && (
        <NoddiDialog title='Measure tires' onClose={() => setMeasureTiresModalOpen(false)} open={measureTiresModalOpen}>
          <MeasureTires carId={bookingItem.car.id} routeItemId={routeItemId} bookingItemId={bookingItem.id} />
        </NoddiDialog>
      )}
      <UnableToCompleteCarModal
        setUnableToCompleteModal={setUnableToCompleteModal}
        unableToCompleteModal={unableToCompleteModal}
        routeId={routeId as string}
        routeItemId={routeItemId.toString()}
        bookingId={bookingItem.id}
        salesItems={salesItemsOnBookingItem}
      />
      <NoddiDialog
        title='Add extra task'
        open={addExtraSalesItemsModalOpen}
        onClose={() => setAddExtraSalesItemsModalOpen(false)}
      >
        <AddExtraServiceToBookingItem
          bookingId={bookingId}
          bookingItem={bookingItem}
          routeItemId={routeItemId}
          salesItemsOnBookingItem={salesItemsOnBookingItem}
        />
      </NoddiDialog>
    </div>
  );
};

export default BookingItemOnRoute;
