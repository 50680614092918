import { URLKeys, noddiAsync } from 'noddi-async';
import { ErrorPage, LoadingScreen } from 'noddi-ui';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { workRoutes } from 'noddi-util';
import HomeContentWrapper from '../../layouts/HomeContentWrapper';
import { getCurrentRouteItem, getNextRouteItem } from '../../utils/routeItem';
import BookingHeaderInfo from './BookingHeaderInfo';
import BookingOnRoute from './BookingOnRoute';
import CurrentRouteItemActionButtons from './CurrentRouteItemActionButtons';
import RouteHeader from './RouteHeader';
import { RouteItemOnRouteStatusBox } from './RouteItemOnRouteStatusBox';

const RouteItemOnRoute = () => {
  const { routeId } = useParams();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const routeItemId = searchParams.get('routeItemId');

  const routeIdAsNumber = parseInt(routeId as string);
  const routeItemIdAsNumber = parseInt(routeItemId as string);

  const {
    data: routeItems,
    isPending: isRoutePending,
    isFetching: isRouteFetching,
    error: routeError
  } = noddiAsync.useGet({
    type: URLKeys.getRouteItemsForServiceWorker,
    input: { routeId: routeIdAsNumber }
  });

  // we listen to isRouteFetching because we want to show loading screen while invalidating query which is not reflected in isPending
  if (isRoutePending || isRouteFetching) {
    return <LoadingScreen />;
  }
  if (routeError) {
    return <ErrorPage apiError={routeError} />;
  }

  const routeItemsOnlyBookings = routeItems.filter((routeItem) => routeItem.type === 'booking');

  const routeItem = routeItemsOnlyBookings.find((item) => item.id === routeItemIdAsNumber);
  const upcomingRouteItem = getCurrentRouteItem(routeItemsOnlyBookings);
  const nextRouteItemAfterCurrent = getNextRouteItem(routeItemsOnlyBookings, upcomingRouteItem);
  const isNextOnRoute = routeItem?.id === upcomingRouteItem?.id;

  const isFinished = !!routeItem?.booking.cancelledAt || !!routeItem?.booking.completedAt;

  return (
    <HomeContentWrapper>
      {routeItem ? (
        <>
          <RouteHeader routeItems={routeItems} routeItemId={routeItem.id} routeId={routeIdAsNumber} />
          {!isNextOnRoute && (
            <div className='mb-2'>
              <RouteItemOnRouteStatusBox routeItem={routeItem} isNextOnRoute={isNextOnRoute} />
            </div>
          )}

          <div className='rounded-lg bg-primary-white px-6 pb-4 pt-7'>
            <BookingHeaderInfo routeItem={routeItem} />
            {routeItem.booking.startedAt && !isFinished && <BookingOnRoute routeItem={routeItem} />}
          </div>
          <CurrentRouteItemActionButtons
            onNavigateOnComplete={() => navigate(workRoutes.todaysAppointments.getPath({ routeId: routeIdAsNumber }))}
            currentRouteItem={routeItem}
            routeId={routeIdAsNumber}
            nextRouteItemAfterCurrent={isNextOnRoute ? nextRouteItemAfterCurrent : null}
          />
        </>
      ) : (
        <div className='mt-5 rounded-lg bg-primary-white px-6 pb-4 pt-7'>
          <p className='text-5 font-semibold text-primary-purple'>Thank you!</p>
          <p>You have completed todays route🎉</p>
        </div>
      )}
    </HomeContentWrapper>
  );
};

export default RouteItemOnRoute;
