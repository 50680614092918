import { NoddiIconButton } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { getIncreasedArrivalTime, getReducedArrivalTime } from './utils';

interface NotificationBlockProps {
  setValue: (value: number) => void;
  value: number;
  onClick: () => Promise<unknown>;
  isLoading: boolean;
  helperText: string;
  title: string;
}

const NotificationBlock = ({ setValue, value, isLoading, onClick, helperText, title }: NotificationBlockProps) => {
  return (
    <div>
      <p className='mb-2 font-semibold'>{title}</p>
      <div className='flex justify-between'>
        <div className='flex items-center justify-center gap-4'>
          <NoddiIconButton
            iconName='Minus'
            variant='secondary'
            iconSize='small'
            onClick={() => {
              const newValue = getReducedArrivalTime(value);
              if (newValue) {
                setValue(newValue);
              }
            }}
          />
          <p>{value} min</p>
          <NoddiIconButton
            iconName='Plus'
            variant='secondary'
            iconSize='small'
            onClick={() => {
              const newValue = getIncreasedArrivalTime(value);
              if (newValue) {
                setValue(newValue);
              }
            }}
          />
        </div>

        <NoddiButton variant='secondary' size='small' loading={isLoading} onPress={onClick}>
          Send
        </NoddiButton>
      </div>
      <p className='mt-2 text-3'>{helperText}</p>
    </div>
  );
};

export default NotificationBlock;
