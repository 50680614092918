import { NoddiIcon } from 'noddi-ui-common';

export const IconBasedOnSalesItem = ({ categorySlug }: { categorySlug?: string | null }) => {
  switch (categorySlug) {
    case 'wheel_services':
      return <NoddiIcon name='WheelAngle' size='medium' />;
    case 'wash_services':
      return <NoddiIcon name='Drop' size='medium' />;
    case 'packages':
      return <NoddiIcon name='Heart' size='medium' />;
    default:
      return <NoddiIcon name='WheelAngle' size='medium' />;
  }
};
