import { Typography } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { AvailableSalesItemForNewCarInBooking, SalesCar } from 'noddi-async/src/types';
import { invalidateQueryKey } from 'noddi-async/src/utils';
import { ApiErrorMessage, NoddiDialog, useNoddiToast } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { useState } from 'react';
import CarSearchResult from './NewCarForm/CarSearchResult';
import LicensePlateSearch from './NewCarForm/LicensePlateSearch';
import SelectSalesItem from './NewCarForm/SelectSalesItem';
import SelectedCars from './NewCarForm/SelectedCars';

const AddExtraCarModal = ({
  bookingId,
  isOpen,
  setOpen
}: {
  bookingId: number;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}) => {
  const [carSearchResult, setCarSearchResult] = useState<SalesCar | null>(null);
  const [isSelectingCar, setIsSelectingCar] = useState(false);
  const [selectedCar, setSelectedCar] = useState<SalesCar | null>(null);
  const [selectedSalesItems, setSelectedSalesItems] = useState<AvailableSalesItemForNewCarInBooking[]>([]);
  const [regNumber, setRegNumber] = useState<string>('');
  const [showCarSearchResult, setShowCarSearchResult] = useState<boolean>(false);
  const { noddiToast } = useNoddiToast();

  const {
    mutateAsync: addSalesItemsToBooking,
    error: addSalesItemsToBookingError,
    isPending: isAddSalesItemsToBookingPending
  } = noddiAsync.usePost({
    type: URLKeys.postAddSalesItemsToBooking,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryKey({
          urlKey: URLKeys.getBookingItemsForRoute
        });
        setSelectedCar(null);
        setSelectedSalesItems([]);
        setRegNumber('');
        setCarSearchResult(null);
        setOpen(false);
      },
      onError: async (error) => {
        noddiToast.asyncError(error);
      }
    }
  });

  return (
    <NoddiDialog title='Add new car' onClose={() => setOpen(false)} open={isOpen}>
      <Typography>Use this if you want to add more cars to an existing appointment</Typography>
      {!selectedCar && (
        <LicensePlateSearch
          setCarSearchResult={setCarSearchResult}
          regNumber={regNumber}
          setRegNumber={setRegNumber}
          setIsSelectingCar={setIsSelectingCar}
          setShowCarSearchResult={setShowCarSearchResult}
        />
      )}

      {isSelectingCar &&
        (showCarSearchResult ? (
          <CarSearchResult
            carSearchResult={carSearchResult}
            regNumber={regNumber}
            onAddCar={() => {
              setShowCarSearchResult(false);
            }}
          />
        ) : (
          carSearchResult && (
            <SelectSalesItem
              carSearchResult={carSearchResult}
              setSelectedCar={setSelectedCar}
              setSelectedSalesItems={setSelectedSalesItems}
              selectedSalesItems={selectedSalesItems}
              bookingId={bookingId}
              onAddService={() => {
                setIsSelectingCar(false);
              }}
            />
          )
        ))}

      {!isSelectingCar && selectedCar && (
        <SelectedCars
          selectedCar={selectedCar}
          onRemoveCar={() => setSelectedCar(null)}
          selectedSalesItems={selectedSalesItems}
        />
      )}
      {addSalesItemsToBookingError && <ApiErrorMessage error={addSalesItemsToBookingError} />}

      <div className='mt-4 flex flex-col gap-4'>
        {!isSelectingCar && (
          <NoddiButton
            className='w-full'
            disabled={!selectedCar}
            loading={isAddSalesItemsToBookingPending}
            onPress={async () => {
              const payload = {
                bookingId,
                cars: [
                  {
                    licensePlateNumber: selectedCar!.licensePlateNumber!,
                    countryCode: 'NO',
                    salesItemIds: selectedSalesItems.map((salesItem) => salesItem.id)
                  }
                ]
              };
              await addSalesItemsToBooking(payload);
            }}
          >
            <Typography variant='body1'>Confirm new vehicle</Typography>
          </NoddiButton>
        )}
      </div>
    </NoddiDialog>
  );
};

export default AddExtraCarModal;
