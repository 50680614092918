import { WorkItem } from 'noddi-async/src/types';
import { NoddiIcon } from 'noddi-ui-common';
import { DateFormats, noddiFormatDate, secondsToHours } from 'noddi-util';

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const WorkCard = ({ workInfo }: { workInfo: WorkItem }) => {
  return (
    <div className='flex flex-col gap-2 rounded-xl bg-primary-white px-4 py-3'>
      <h5 className='font-bold'>
        {capitalizeFirstLetter(noddiFormatDate(new Date(workInfo.date), DateFormats.FULL_DAY_MONTH))}
      </h5>
      <div className='flex gap-2'>
        <div>
          <NoddiIcon name='LocationPin' size='medium' />
        </div>
        <p>
          {`${workInfo.startAddress.streetName} ${workInfo.startAddress.streetNumber} - ${workInfo.endAddress.streetName} ${workInfo.endAddress.streetNumber}`}
        </p>
      </div>

      <div className='flex gap-2'>
        <div>
          <NoddiIcon name='ClockCircle' size='medium' />
        </div>
        <p>
          {`${noddiFormatDate(workInfo.startTime, DateFormats.TIME)} - ${noddiFormatDate(workInfo.endTime, DateFormats.TIME)}, Max ${secondsToHours(workInfo.maxDurationInSeconds)} h`}
        </p>
      </div>
    </div>
  );
};

export default WorkCard;
