import { Divider, MenuItem, Select, Stack, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { URLKeys, noddiAsync } from 'noddi-async';
import { NoddiLinearProgressLoader } from 'noddi-ui';
import { noddiColors } from 'noddi-ui-common';

export interface UnableToCompleteItem {
  id: number;
  unableToCompleteReasonId?: number | null;
  name: string;
  isSelected: boolean;
}

interface UnableToCompleteCarModalProps {
  setUnableToCompleteItems: (salesItems: UnableToCompleteItem[]) => void;
  unableToCompleteItem: UnableToCompleteItem;
}

export default function UnableToCompleteBlock({
  unableToCompleteItem,
  setUnableToCompleteItems
}: UnableToCompleteCarModalProps) {
  const { data: unableToCompleteReasons, isPending: isUnableReasonsPending } = noddiAsync.useGet({
    type: URLKeys.getUnableToCompleteReasons,
    input: { isForBooking: false, salesItemIds: [unableToCompleteItem.id] }
  });

  return (
    <Stack>
      <Stack>
        <Stack direction='row' alignItems='center'>
          <Typography>{unableToCompleteItem.name}</Typography>
          <Checkbox
            style={{ color: noddiColors.primary.orange }}
            checked={unableToCompleteItem.isSelected}
            onChange={(e) => {
              // @ts-ignore TODO: fix ts error
              setUnableToCompleteItems((prev) =>
                // @ts-ignore TODO: fix ts error
                prev.map((item) =>
                  item.id === unableToCompleteItem.id ? { ...item, isSelected: e.target.checked } : item
                )
              );
            }}
          />
          <Divider />
        </Stack>
      </Stack>

      {isUnableReasonsPending ? (
        <NoddiLinearProgressLoader />
      ) : unableToCompleteItem.isSelected ? (
        <>
          <Typography color='text.secondary'>Select unable to complete reason</Typography>
          <Select
            name='unable_reasons'
            fullWidth
            value={unableToCompleteItem.unableToCompleteReasonId || ''}
            onChange={(e) => {
              // @ts-ignore TODO: fix ts error
              setUnableToCompleteItems((prev) =>
                // @ts-ignore TODO: fix ts error
                prev.map((item) =>
                  item.id === unableToCompleteItem.id ? { ...item, unableToCompleteReasonId: e.target.value } : item
                )
              );
            }}
          >
            {unableToCompleteReasons?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </>
      ) : null}
    </Stack>
  );
}
