import { workRoutes } from 'noddi-util';

export const getNavItems = () => [
  {
    items: [
      {
        path: workRoutes.routes.getPath(),
        title: 'Routes'
      }
    ]
  },
  {
    items: [
      {
        path: workRoutes.calender.getPath(),
        title: 'Availability'
      }
    ]
  },
  {
    items: [
      {
        path: workRoutes.profile.getPath(),
        title: 'Work profile'
      }
    ]
  }
];
