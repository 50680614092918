import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { ApiErrorMessage, LoadingScreen } from 'noddi-ui';
import { NoddiButton, NoddiIcon } from 'noddi-ui-common';
import { workRoutes } from 'noddi-util';
import { useNavigate } from 'react-router-dom';
import HomeContentWrapper from '../../layouts/HomeContentWrapper';
import MyPayments from '../MyPayments';

const Profile = () => {
  const navigate = useNavigate();
  const { userData } = useAuthContext();

  const {
    data: user,
    error: userError,
    isPending: isUserPending
  } = noddiAsync.useGet({
    type: URLKeys.getUser,
    input: { id: userData?.user.id },
    queryConfig: {
      enabled: !!userData?.user.id
    }
  });

  if (!userData || isUserPending) {
    return <LoadingScreen />;
  }

  if (userError) {
    return <ApiErrorMessage error={userError} />;
  }

  return (
    <HomeContentWrapper title='Work profile'>
      <div className='-mx-3 flex flex-col gap-4 xxs:-mx-2'>
        <div className='flex flex-col gap-4 rounded-lg bg-primary-white px-4 py-5'>
          <p className='text-5 font-semibold'>
            {user.firstName} {user.lastName}
          </p>
          <div className='flex items-center gap-2'>
            <NoddiIcon name='Phone' size='medium' />
            <p>{user.phoneNumber}</p>
          </div>
          <div className='flex items-center gap-2'>
            <NoddiIcon name='Home' size='medium' />
            <p>{user.email}</p>
          </div>

          <div className='flex flex-col gap-2'>
            <NoddiButton
              startIcon='Edit'
              className='gap-2 pl-0'
              onPress={() => navigate(workRoutes.editContactInfo.getPath())}
              variant='link'
            >
              <p>Edit account information</p>
            </NoddiButton>
            <NoddiButton
              startIcon='Star'
              className='gap-2 pl-0'
              onPress={() => navigate(workRoutes.feedback.getPath())}
              variant='link'
            >
              <p>Show my feedback</p>
            </NoddiButton>
          </div>
        </div>

        <MyPayments />
      </div>
    </HomeContentWrapper>
  );
};

export default Profile;
