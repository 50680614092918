import { StorageObject } from 'noddi-async/src/types';
import { NoddiButton } from 'noddi-ui-common';

import { StorageObjectCard } from './StorageObjectCard';

type Props = {
  storageObject: StorageObject;
  onPickUp: () => void;
  isLoading: boolean;
};

export const WheelsStored = ({ storageObject, onPickUp, isLoading }: Props) => {
  return (
    <div className='flex flex-col gap-4'>
      <StorageObjectCard storageObject={storageObject} />

      <NoddiButton onPress={onPickUp} loading={isLoading}>
        Pick up wheels
      </NoddiButton>
    </div>
  );
};
