import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { DEFAULT_LICENSE_PLATE_COUNTRY_CODE, URLKeys, noddiAsync } from 'noddi-async';
import { SalesCar } from 'noddi-async/src/types';
import { NoddiButton } from 'noddi-ui-common';
import { Dispatch, SetStateAction } from 'react';

import { convertRegisteredCarToSalesCar } from './helper';

type Props = {
  setCarSearchResult: Dispatch<SetStateAction<SalesCar | null>>;
  regNumber: string;
  setRegNumber: Dispatch<SetStateAction<string>>;
  setIsSelectingCar: Dispatch<SetStateAction<boolean>>;
  setShowCarSearchResult: Dispatch<SetStateAction<boolean>>;
};

const LicensePlateSearch = ({
  regNumber,
  setRegNumber,
  setCarSearchResult,
  setIsSelectingCar,
  setShowCarSearchResult
}: Props) => {
  const onRegNumberChange = (val: string) => {
    const strippedRegNumberInput = val.split(' ').join('');
    setRegNumber(strippedRegNumberInput.toUpperCase());
  };

  const { refetch, isLoading } = noddiAsync.useGet({
    type: URLKeys.getDataFromLicensePlateNumber,
    input: { licensePlateNumber: regNumber, countryCode: DEFAULT_LICENSE_PLATE_COUNTRY_CODE }
  });

  const onSearch = async () => {
    const { data } = await refetch();
    if (data) {
      setCarSearchResult(convertRegisteredCarToSalesCar(data));
      setIsSelectingCar(true);
      setShowCarSearchResult(true);
    }
  };

  return (
    <TextField
      label='License plate'
      placeholder='AB12345'
      style={{ marginTop: '12px' }}
      fullWidth
      value={regNumber}
      onChange={(e) => onRegNumberChange(e.target.value)}
      onKeyDown={async (e) => {
        if (e.key === 'Enter') {
          await onSearch();
        }
      }}
      inputProps={{
        maxLength: 7
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <NoddiButton
              variant='secondary'
              onPress={async () => onSearch()}
              disabled={isLoading}
              startIcon='Magnifier'
            >
              Search
            </NoddiButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default LicensePlateSearch;
