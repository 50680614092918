/* eslint-disable deprecation/deprecation */
const apiVersion = '/v1/';
/** @deprecated */
export enum ApiEndpointIdentifier {
  UNABLE_TO_COMPELTE_CAR = 'unable-to-complete-car',
  SERVICE_WORKER_FEEDBACKS = 'service-worker-feedbacks',
  SERVICE_WORKER_FEEDBACKS_WITH_DATES = 'service-worker-feedbacks-with-dates'
}

type ApiEndPoint = {
  name: ApiEndpointIdentifier;
  path: string;
};
/** @deprecated */
export const ApiEndPoints: ApiEndPoint[] = [
  {
    name: ApiEndpointIdentifier.UNABLE_TO_COMPELTE_CAR,
    path: `${apiVersion}routes/:routeId/route-items/:routeItemId/mark-booking-item-as-unable-to-complete/`
  },
  {
    name: ApiEndpointIdentifier.SERVICE_WORKER_FEEDBACKS,
    path: `${apiVersion}service-feedbacks/for-worker/?page_size=:pageSize&page=:page`
  },
  {
    name: ApiEndpointIdentifier.SERVICE_WORKER_FEEDBACKS_WITH_DATES,
    path: `${apiVersion}service-feedbacks/for-worker/?page_size=:pageSize&page=:page&from_date=:fromDate&to_date=:toDate`
  }
];

export const ApiKeys = Object.values(ApiEndpointIdentifier);
