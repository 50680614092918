import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { noddiAsync } from 'noddi-async';
import { AuthProvider, NoddiLocalizationProvider, useAuthContext } from 'noddi-provider';
import {
  APIBanner,
  createTheme,
  Error404Page,
  ForbiddenPage,
  NbFallback,
  NoddiCircularLoader,
  ToastProvider
} from 'noddi-ui';
import queryString from 'query-string';
import { Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { workRoutes } from 'noddi-util';
import HomePrivateLayout from './layouts/HomePrivateLayout';
import EditContactInfo from './pages/EditContactInfo';
import Login from './pages/Login';
import MyFeedback from './pages/MyFeedback';
import MyRoutes from './pages/MyRoutes';
import Profile from './pages/Profile.tsx';
import ServiceWorkerRoute from './pages/ServiceWorkerRoute';
import RouteItemOnRoute from './pages/ServiceWorkerRoute/RouteItemOnRoute';
import CarWheelSet from './pages/Storage/CarWheelSet';
import WorkPreferences from './pages/WorkPreferences';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  const theme = createTheme();

  const NoddiServerContext = noddiAsync.NoddiServerContext;

  return (
    <Sentry.ErrorBoundary
      fallback={<NbFallback />}
      onError={(err, componentStack) => {
        Sentry.captureException(componentStack, {
          level: 'error',
          extra: {
            error: err
          }
        });
      }}
    >
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{
          searchStringToObject: queryString.parse,
          objectToSearchString: queryString.stringify
        }}
      >
        <NoddiServerContext>
          <AuthProvider>
            <ThemeProvider theme={theme}>
              <ToastProvider>
                <NoddiLocalizationProvider>
                  <Router />
                  <APIBanner />
                </NoddiLocalizationProvider>
              </ToastProvider>
            </ThemeProvider>
          </AuthProvider>
        </NoddiServerContext>
      </QueryParamProvider>
    </Sentry.ErrorBoundary>
  );
}

const Router = () => {
  const { isTokenLoadingOnMount } = useAuthContext();

  if (isTokenLoadingOnMount) {
    return (
      <div className='flex min-h-screen justify-center bg-pint-to-lighterPint-gradient'>
        <NoddiCircularLoader />
      </div>
    );
  }

  return (
    <SentryRoutes>
      <Route path={workRoutes.login.getBasePath()} element={<Login />} />
      <Route path={workRoutes.error.getBasePath()} element={<Error404Page />} />
      <Route path='*' element={<Error404Page />} />
      <Route path={workRoutes.forbiddenPage.getBasePath()} element={<ForbiddenPage />} />

      <Route element={<HomePrivateLayout />}>
        <Route path={workRoutes.home.getBasePath()} element={<MyRoutes />} />
        <Route path={workRoutes.profile.getBasePath()} element={<Profile />} />
        <Route path={workRoutes.editContactInfo.getBasePath()} element={<EditContactInfo />} />
        <Route path={workRoutes.calender.getBasePath()} element={<WorkPreferences />} />
        <Route path={workRoutes.routes.getBasePath()} element={<MyRoutes />} />
        <Route path={workRoutes.routeItemOnRoute.getBasePath()} element={<RouteItemOnRoute />} />
        <Route path={workRoutes.feedback.getBasePath()} element={<MyFeedback />} />
        <Route path={workRoutes.carWheelSet.getBasePath()} element={<CarWheelSet />} />
        <Route path={workRoutes.todaysAppointments.getBasePath()} element={<ServiceWorkerRoute />} />
      </Route>
    </SentryRoutes>
  );
};

export default App;
