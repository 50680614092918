import { StorageObject } from 'noddi-async/src/types';
import { NoddiIcon, noddiColors } from 'noddi-ui-common';
import { DateFormats, noddiFormatDate } from 'noddi-util';

type Props = {
  storageObject: StorageObject;
};

export const StorageObjectCard = ({ storageObject }: Props) => {
  const {
    pickedUpAt,
    deliveredAt,
    storageUnit: { fullName },
    updatedBy: { name }
  } = storageObject;

  const pickedUpTime = noddiFormatDate(pickedUpAt, DateFormats.DASHED_DATE_TIME);
  const deliveredAtTime = noddiFormatDate(deliveredAt, DateFormats.DASHED_DATE_TIME);

  return (
    <div className='flex flex-col items-start rounded-2xl bg-primary-white p-3'>
      <div className='flex gap-2'>
        <NoddiIcon name='Wheel' size='medium' />
        <p>All wheels </p>
      </div>
      {pickedUpTime ? (
        <div className='flex gap-2'>
          <NoddiIcon name='LocationPin' size='medium' />
          <div>
            <p>{`Picked up ${pickedUpTime}`}</p>
            <p>{`by ${name}`}</p>
          </div>
        </div>
      ) : (
        <div className='flex gap-2'>
          <NoddiIcon name='Home' size='medium' />
          <div>
            <p>{fullName}</p>
            <p>{`Stored ${deliveredAtTime}`}</p>
          </div>
        </div>
      )}
      <div className='flex items-center gap-2'>
        {pickedUpTime ? (
          <>
            <NoddiIcon color={noddiColors.signal.danger} size='medium' name='Cross' />
            <p className='text-signal-danger'>In transit</p>
          </>
        ) : (
          <>
            <NoddiIcon color={noddiColors.signal.success} size='medium' name='Check' />
            <p className='text-signal-success'>Stored</p>
          </>
        )}
      </div>
    </div>
  );
};
