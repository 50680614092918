import { AnimatePresence, motion } from 'framer-motion';
import { URLKeys, noddiAsync } from 'noddi-async';
import { RouteItemForServiceWorker } from 'noddi-async/src/types';
import { ApiErrorMessage, NoddiLinearProgressLoader } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { useState } from 'react';
import AddExtraCarModal from '../../components/AddExtraCar';
import BookingItemOnRoute from './BookingItemOnRoute';

interface BookingOnRouteProps {
  routeItem: RouteItemForServiceWorker;
}

const BookingOnRoute = ({ routeItem }: BookingOnRouteProps) => {
  const [isExtraCarModalOpen, setIsExtraCarModalOpen] = useState(false);

  const {
    data: activeBookingItems,
    isPending: isActiveBookingItemsPending,
    isFetching: isActiveBookingItemsFetching,
    error: activeBookingItemsError
  } = noddiAsync.useGet({
    type: URLKeys.getBookingItemsForRoute,
    input: { routeItemId: routeItem.id }
  });

  if (isActiveBookingItemsPending || isActiveBookingItemsFetching) {
    return <NoddiLinearProgressLoader />;
  }

  if (activeBookingItemsError) {
    return <ApiErrorMessage error={activeBookingItemsError} />;
  }

  return (
    <div>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.5 }}
          className='mt-4'
        >
          {activeBookingItems.map((bookingItem) => (
            <div className='border-t' key={bookingItem.id}>
              <BookingItemOnRoute
                bookingId={routeItem.booking.id}
                bookingItem={bookingItem}
                routeItemId={routeItem.id}
              />
            </div>
          ))}
          <div className='border-t'>
            <p className='mb-3 mt-4 text-3.5'>
              Does the customer have more cars that you will perform service on today?
            </p>
            <NoddiButton
              onPress={() => setIsExtraCarModalOpen(true)}
              variant='secondary'
              className='w-full'
              startIcon='Plus'
            >
              Add extra car
            </NoddiButton>
          </div>
        </motion.div>
      </AnimatePresence>
      {isExtraCarModalOpen && (
        <AddExtraCarModal
          isOpen={isExtraCarModalOpen}
          setOpen={setIsExtraCarModalOpen}
          bookingId={routeItem.booking.id}
        />
      )}
    </div>
  );
};

export default BookingOnRoute;
