import { PropsWithChildren } from 'react';

const NavWrapper = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <div className='relative top-4 mx-auto mb-4 max-w-screen-lg rounded-full bg-primary-white/60 px-5 py-1.5 max-lg:mx-4 md:px-10 md:py-4'>
      {children}
    </div>
  );
};

export default NavWrapper;
