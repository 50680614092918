import Box from '@mui/material/Box';
import { Feature, LineString } from 'geojson';
import { LngLatBounds } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import ReactMap, { FullscreenControl, Layer, MapProps, MapRef, Popup, Source } from 'react-map-gl';

import { noddiColors } from 'noddi-ui-common';
import NoddiMapMarker from './Markers';
import { MarkerProps } from './interface';
import './mapbox-custom-styles.css';

const DEFAULT_MAP_CENTER = { latitude: 59.9299283, longitude: 10.6738627, zoom: 9 }; // Default location is Oslo, Norway

type Props = MapProps & {
  initialBounds?: { latitude: number; longitude: number; zoom: number };
  markers?: MarkerProps[];
  height?: string;
  width?: string;
  fullscreenControl?: boolean;
  fullScreenControlPosition?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';
  routeGeoJson?: Feature<LineString>[] | null;
};
const mapboxApiAccessToken = import.meta.env.VITE_APP_MAPBOX_API_KEY;

// Function to calculate the center of the markers
const getCenterOfMarkers = (markers: MarkerProps[]) => {
  const latitudes = markers.map((marker) => marker.latitude);
  const longitudes = markers.map((marker) => marker.longitude);
  const avgLatitude = latitudes.reduce((a, b) => a + b, 0) / latitudes.length;
  const avgLongitude = longitudes.reduce((a, b) => a + b, 0) / longitudes.length;

  return { latitude: avgLatitude, longitude: avgLongitude, zoom: 9 };
};

export const MapboxMap = ({
  markers,
  routeGeoJson,
  initialBounds,
  fullscreenControl,
  fullScreenControlPosition,
  height,
  width,
  ...mapProps
}: Props) => {
  const [currentActiveMarkerId, setCurrentActiveMarkerId] = useState<string | number | undefined>();
  const [popupInfo, setPopupInfo] = useState<{
    latitude: number;
    longitude: number;
    content: string | ReactNode;
  } | null>(null);
  const rootRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<MapRef | null>(null);

  const [viewState, setViewState] = useState(() => {
    if (initialBounds) {
      return initialBounds;
    }
    if (markers && markers.length > 0) {
      return getCenterOfMarkers(markers);
    }
    return DEFAULT_MAP_CENTER;
  });

  // Function to calculate the bounds based on markers
  const getBounds = (markers: MarkerProps[]) => {
    const longitudes = markers.map((marker) => marker.longitude);
    const latitudes = markers.map((marker) => marker.latitude);
    return LngLatBounds.convert([
      [Math.min(...longitudes), Math.min(...latitudes)],
      [Math.max(...longitudes), Math.max(...latitudes)]
    ]);
  };

  // Set the bounds of the map based on the markers
  useEffect(() => {
    if (!mapRef.current || !markers?.length) {
      return;
    }
    const bounds = getBounds(markers);
    mapRef.current.fitBounds(bounds, { maxZoom: 15, padding: 50 });
  }, [markers]);

  // Resize the map when the root element resizes
  useEffect(() => {
    const root = rootRef.current;
    const map = mapRef.current;
    if (!root || !map) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const _entry of entries) {
        map.resize();
      }
    });

    resizeObserver.observe(root);
    return () => {
      resizeObserver.unobserve(root);
    };
  }, [mapRef]);

  // Function to recenter the map based on the current active marker
  const handleRecenter = useCallback(() => {
    const map = mapRef.current;
    if (!map) {
      return;
    }

    const marker = markers?.find((marker) => marker.id === currentActiveMarkerId);
    const flyOptions: { center: [number, number] } = {
      center: marker ? [marker.longitude, marker.latitude] : [viewState.longitude, viewState.latitude]
    };

    map.flyTo(flyOptions);
  }, [markers, currentActiveMarkerId, viewState]);

  useEffect(() => {
    if (!markers?.length || !currentActiveMarkerId) {
      return;
    }
    handleRecenter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markers, currentActiveMarkerId]);

  if (!mapboxApiAccessToken) {
    throw new Error('Mapbox API access token is missing');
  }

  return (
    <Box
      component='main'
      ref={rootRef}
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        height: height ?? '100%',
        width: width ?? '100%',
        overflow: 'hidden',
        position: 'relative',
        borderRadius: '10px'
      }}
    >
      <Box
        sx={{
          flex: '1 1 auto',
          overflow: 'hidden',
          position: 'relative'
        }}
      >
        {/* 
// @ts-ignore */}
        <ReactMap
          {...viewState}
          mapboxAccessToken={mapboxApiAccessToken}
          style={{ width: '100%', height: '100%' }}
          ref={mapRef}
          onMove={(evt) => setViewState(evt.viewState)}
          mapStyle='mapbox://styles/mapbox/streets-v9'
          {...mapProps}
        >
          {fullscreenControl && <FullscreenControl position={fullScreenControlPosition} />}
          {markers?.map((marker) => (
            <NoddiMapMarker
              key={marker.id}
              marker={marker}
              currentActiveMarkerId={currentActiveMarkerId}
              setCurrentActiveMarkerId={(id) => {
                setCurrentActiveMarkerId(id);
                const clickedMarker = markers.find((m) => m.id === id);
                if (clickedMarker) {
                  setPopupInfo({
                    latitude: clickedMarker.latitude,
                    longitude: clickedMarker.longitude,
                    content: marker.contentToShowOnClick
                  });
                }
              }}
            />
          ))}
          {popupInfo && (
            <Popup
              latitude={popupInfo.latitude}
              longitude={popupInfo.longitude}
              onClose={() => setPopupInfo(null)}
              closeOnClick={false}
            >
              {popupInfo.content}
            </Popup>
          )}

          {routeGeoJson?.map((segment, index) => (
            <Source key={`route-segment-${index}`} id={`route-segment-${index}`} type='geojson' data={segment}>
              <Layer
                id={`route-layer-${index}`}
                type='line'
                paint={{
                  'line-color': segment.properties?.color || noddiColors.primary.darkPurple,
                  'line-width': 3,
                  'line-opacity': 0.8
                }}
              />
            </Source>
          ))}
        </ReactMap>
      </Box>
    </Box>
  );
};
